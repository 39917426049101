<template lang="html">
  <PageHeader />

  <section class="mt-stickybar licence-agreement home" aria-label="licence-agreement">
    <div class="inner-page-content py-5">
      <div class="container">
        <!-- <div v-html="Details"></div> -->

        <div class="row">
          <!-- {{ licenseAgreementTermination }} -->
          <div class="col-md-12">
            <h2>Privacy Policy for StoryPhones</h2>
            <p>
              OnanOff Ltd, the creators of StoryPhones ("we," "us," or "our") is committed to protecting your privacy.
              This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you
              use our mobile application StoryPhones ("the App") available on the Google Play Store. </p>

            <h5>1. Information We Collect</h5>
            <ul class="ms-4 mb-2" style="list-style-type: lower-alpha;">
              <li>Profile Images: The App may collect and store images uploaded by users for profile management purposes.</li>
              <li>Audio Files: The App may collect and store audio files provided by users for playlist creation for headphone usage.</li>
            </ul>
            <h5>2. How We Use Your Information</h5>
            <ul class="ms-4 mb-2" style="list-style-type: lower-alpha;">
              <li>Profile Images: We use the collected profile images to personalize the user experience within the App and enhance profile management features.</li>
              <li>Audio Files: The collected audio files are used solely for creating playlists within the App for headphone usage.</li>
            </ul>
            <h5>3. Data Security</h5>
            <ul class="ms-4 mb-2">
              <li>We implement reasonable security measures to protect the confidentiality and integrity of the information collected. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</li>
            </ul>
            <h5>4. Sharing of Information</h5>
            <ul class="ms-4 mb-2">
              <li>We do not sell, trade, or otherwise transfer your personal information to third parties. However, we may share your information with trusted third parties who assist us in operating our App, conducting our business, or servicing you.</li>
            </ul>
            <h5>5. Consent</h5>
            <ul class="ms-4 mb-2">
              <li>By using the App, you consent to the terms of this Privacy Policy and agree to the collection and use of your information as outlined herein.</li>
            </ul>
            <h5>6. Changes to this Privacy Policy</h5>
            <ul class="ms-4 mb-2">
              <li>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Changes will be effective upon posting the updated Privacy Policy on the App. It is your responsibility to review this Privacy Policy periodically.</li>
            </ul>
            <h5>7. Contact Information</h5>
            <ul class="ms-4 mb-2">
              <li>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:help@storyphones.com" class="text-link">help@storyphones.com</a></li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </section>
  <PageFooter />
</template>

<script lang="js">

import { useStore } from 'vuex';
import { ref, computed } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';
import PageHeader from '@/components/commons/PageHeader.vue';
import PageFooter from '@/components/commons/PageFooter.vue';
import PageService from '@/services/PageService';

const pageService = new PageService();
const ent = require('ent');

export default {
  name: 'privacy-policy',
  props: [],
  components: {
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const responseContainer = ref(null);

    const LangCode = localStorage.getItem('LangCode');
    // const data = {
    //   code: LangCode,
    //   slug: `license_agreement_${LangCode}`,
    // };

    // store.dispatch('fetchCMS', data);

    // const data1 = {
    //   code: LangCode,
    //   slug: `license_agreement_general_${LangCode}`,
    // };

    // store.dispatch('fetchCMS1', data1);

    // const data2 = {
    //   code: LangCode,
    //   slug: `license_agreement_email_${LangCode}`,
    // };

    // store.dispatch('fetchCMS2', data2);

    // const data3 = {
    //   code: LangCode,
    //   slug: `license_agreement_termination_${LangCode}`,
    // };

    // store.dispatch('fetchCMS3', data3);

    // const data4 = {
    //   code: LangCode,
    //   slug: `license_agreement_transferability_${LangCode}`,
    // };

    // store.dispatch('fetchCMS4', data4);

    // const data5 = {
    //   code: LangCode,
    //   slug: `license_agreement_services_${LangCode}`,
    // };

    // store.dispatch('fetchCMS5', data5);

    // const data6 = {
    //   code: LangCode,
    //   slug: `license_agreement_disclaimer_${LangCode}`,
    // };

    // store.dispatch('fetchCMS6', data6);

    // const data7 = {
    //   code: LangCode,
    //   slug: `license_agreement_indemnification_${LangCode}`,
    // };

    // store.dispatch('fetchCMS7', data7);

    // const decode = async (value) => {
    //   const text = await ent.decode(value);
    //   return text.value;
    // };

    // const licenseAgreement = computed(() => store.getters.getCMS);
    // const Details = computed(() => DOMPurify.sanitize(licenseAgreement.value.html_description));

    // console.log(Details);

    // const licenseAgreementGeneral = computed(() => store.getters.getCMS1);

    // const licenseAgreementEmail = computed(() => store.getters.getCMS2);

    // const licenseAgreementTermination = computed(() => store.getters.getCMS3);

    // const licenseAgreementTransferability = computed(() => store.getters.getCMS4);

    // const licenseAgreementServices = computed(() => store.getters.getCMS5);

    // const licenseAgreementDisclaimer = computed(() => store.getters.getCMS6);

    // const licenseAgreementIndemnification = computed(() => store.getters.getCMS7);

    // const decodeHtml = (html) => {
    //   const parser = new DOMParser();
    //   const decoded = parser.parseFromString(html, 'text/html').body.textContent;
    //   return decoded;
    // };
    return {
      // licenseAgreement,
      // licenseAgreementGeneral,
      // licenseAgreementEmail,
      // licenseAgreementTermination,
      // licenseAgreementTransferability,
      // licenseAgreementServices,
      // licenseAgreementDisclaimer,
      // licenseAgreementIndemnification,
      // decode,
      // decodeHtml,
      responseContainer,
      // Details,
    };
  },
  mounted() {

  },
  data() {
    return {

    };
  },
  methods: {

  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
.text-link {
  color: blue;
  text-decoration: underline;
}
</style>
